import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const [rotate, setRotate] = useState(0);
  return (
    <div className="App">
      <header className="App-header">
        <div
          title={"Tap Team"}
          className={"App-container"}
          onClick={() => {
            console.log(1);
            window.location.href = "https://vk.com/tap_team_studio";
          }}
        >
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div
          onClick={() => {
            if (rotate === 0) setRotate(180);
            else setRotate(0);
          }}
          style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}
        >
          <p>
            Ta
            <span
              className={["span", rotate !== 0 ? "span-rotated" : ""].join(" ")}
            >
              p
            </span>{" "}
            Team
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
