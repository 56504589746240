// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}
.App-container {
  border-radius: 50%;
  height: 30vmin;
  width: 30vmin;
  background: white;
  position: relative;
  padding: 0;
}
.App-logo {
  transform: translate(-12.5%, -12.5%);
  height: 40vmin;
  pointer-events: none;
}

.span {
  display: inline-block;
  margin: 0;
  transition: all 0.5s ease;
}
.span-rotated {
  transform: rotateX(180deg) translateY(-20%);
}
@media (prefers-reduced-motion: no-preference) {
  .App-container {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,yBAAyB;AAC3B;AACA;EACE,2CAA2C;AAC7C;AACA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n.App-container {\n  border-radius: 50%;\n  height: 30vmin;\n  width: 30vmin;\n  background: white;\n  position: relative;\n  padding: 0;\n}\n.App-logo {\n  transform: translate(-12.5%, -12.5%);\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.span {\n  display: inline-block;\n  margin: 0;\n  transition: all 0.5s ease;\n}\n.span-rotated {\n  transform: rotateX(180deg) translateY(-20%);\n}\n@media (prefers-reduced-motion: no-preference) {\n  .App-container {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
